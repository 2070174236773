import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
import "../assets/vuetify-fix.scss"
import "../assets/evalmee_theme.scss"

Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
})
export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: "#F8FBFF",
        accentLight: "#F0F4FA",
        primaryLight: "#66ACFF",
        primaryLight2: "#B6D6FB",
        // primary: colors.purple,
        // secondary: colors.grey.darken1,
        // accent: colors.shades.black,
        // error: colors.red.accent3,
      },
      dark: {
        background: "#24242e",
        // primary: colors.blue.lighten3,
      },
    },
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
})
