<template>
  <v-btn
    :color="primary ? 'primary' : color"
    :small="dense"
    v-bind="$attrs"
    v-on="$listeners"
    depressed
    class="eva-button"
    :class="{'icon-only': icon,
             'pa-1': icon,}"
  >
    <v-icon
      v-if="iconLeft"
      left
      :small="dense"
      v-html="iconLeft"
    />
    <slot v-if="!icon">
      {{ label }}
    </slot>
    <v-icon
      v-if="iconRight"
      right
      :small="dense"
      v-html="iconRight"
    />
    <v-icon
      v-if="icon"
      :small="dense"
      v-html="icon"
    />
  </v-btn>
</template>

<script>
export default {
  name: "EvaButton",
  props: {
    primary: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    iconLeft: { type: String, default: null },
    iconRight: { type: String, default: null },
    label: { type: String, default: null },
    color: { type: String, default: null },
    icon: { type: String, default: null },
  },
}
</script>

<style>
.eva-button.v-size--small:not(.v-btn--icon){
  min-width: 28px !important;
}
.eva-button.icon-only {
  min-width: 28px !important;
}
</style>
